import React from 'react'
import styles from '../styles/landinglinks.module.css'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Tooltip from "react-simple-tooltip"

const ProjectLinks = () => {
  return (
<>
    <Row>
      <Col sm={6}>
        <div className={styles.worksection}>
        <h2>Portfolio</h2>
        <br />
        <hr className="divider" />
        <br />
        <p><Tooltip 
        content="Please get in touch for a walkthrough of my work on ClassLink's suite of apps."
        fadeDuration={1}
        fadeEasing="linear"
        className="tooltip-container">
          <button className='hovertext'>ClassLink Product Suite</button></Tooltip> | UX, UI, Team Direction | 2019-Present</p><br />
        <p><a href="/lazada-express-ecommerce-app/">Lazada Express App</a> | UX, UI, Ecommerce | 2017-2018</p><br />
        <p><a href="/lazada-express-branding/">Lazada Express Branding</a> | Branding, Graphic Design | 2018</p><br />
        <p><a href="/terradiol-company-website/">Terradiol Website</a> | Web Design | 2016</p><br />
        <p><a href="/dinein-ui-revamp/">DINEIN UI Revamp</a> | UX, UI | 2015</p><br />
        <p><a href="/sheri-koetting-site/">Sheri Koetting Site</a> | Graphic Design, Web Design | 2015</p>
        </div>
      </Col>
      <Col sm={6}>
        <div>
        <h2>Blog</h2>
        <br />
        <hr className="divider" />
        <br />
        <p><a href="/fpv-footage">FPV Footage</a></p><br />
        <p><a href="/my-photography">My Photography</a></p><br />
        <p><a href="/volunteer-animation-work">Volunteer Animation Work</a></p><br />
        <p><a href="/archviz-rendering">Archviz Rendering</a></p><br />
        <p><a href="/woodworking-projects/">Woodworking Projects</a></p><br />
        <p><a href="/ceramic-projects/">Ceramics Projects</a></p>
        </div>
      </Col>
      <Col sm={6}>
        <div>
        <h2>Site Archive</h2>
        <br />
        <hr className="divider" />
        <br />
        <p><a href="http://2017.erikreiblein.com">2017 Portfolio</a></p><br />
        </div>
      </Col>
    </Row>
    </>
  )
}

export default ProjectLinks
